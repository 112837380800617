import './Home.css'
import BlueSection from './BlueSection'
import HomeTitle from './HomeTitle'
import Section2 from './Section2'
import Section3 from './Section3'
import Appointment from './Appointment'
import Memorandum from './Memorandum'

function Home() {
    return (
        <>
        {/* <Memorandum /> */}
            <HomeTitle />
            <BlueSection />
            <Section2 />
            <Section3 />
            <Appointment />
        </>
    )
}

export default Home
