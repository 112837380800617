import React from 'react'
import {useTranslation} from 'react-i18next'
import {useState, useEffect, useRef} from 'react'
import {Link, NavLink} from 'react-router-dom'
import './Header.css'
import logo from '../../img/ll.png'

const Navbar = () => {
  const [navColor, setnavColor] = useState('transparent')

  const menuRef = useRef()

  const listenScrollEvent = () => {
    window.scrollY > 700 ? setnavColor('#051f1f') : setnavColor('transparent');
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)

    document.addEventListener('mousedown', (e) => {
      !menuRef.current?.contains(e.target) && setMenuOpen(false) 
    })

    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  isMenuOpen ? document.body.classList.add('active-menu') : document.body.classList.remove('active-menu') 

  

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const {t, i18n} = useTranslation()

  function handleClick(lang) {
    i18n.changeLanguage(lang)
  }
  function scrollToDown(){
    window.scrollTo(0,800)
  }
  return (
    <header>
      <nav ref={menuRef} style={{backgroundColor: navColor}} className={`navbar ${isMenuOpen ? 'active' : ''}`}>
        <Link to='/' className='nav-branding'>
          <div><img src={logo} alt='Logo' /></div>
        </Link>
        <ul className={`nav-menu ul ${isMenuOpen ? 'active' : ''}`}>
          <li className='nav-item nav-item-link'>
            <NavLink to='/' className='nav-link' onClick={closeMenu}>
              {t('menu.home')}
            </NavLink>
          </li>
          <li className='nav-item nav-item-link'>
            <NavLink to='fabrics' className='nav-link' onClick={closeMenu}>
            {t('menu.fabrics')}
            </NavLink>
          </li>
          <li className='nav-item nav-item-link'>
            <NavLink to='collections' className='nav-link' onClick={closeMenu}>
            {t('menu.gallery')}
            </NavLink>
          </li>
          <li className='nav-item nav-item-link'>
            <NavLink to='stores' className='nav-link' onClick={closeMenu}>
            {t('menu.stores')}
            </NavLink>
          </li>
          <li id='languages' className='text-white nav-item-link '>
            <div className='dropdown'>
              <Link className='dropbtn nav-item nav-link' onClick={() => handleClick(`${t('languages.dropbtn')}`)}>{t('languages.dropbtn')}</Link>
              <div className="dropdown-content">
                <Link className='nav-link' onClick={() => handleClick(`${t('languages.lang1')}`)}>{t('languages.lang1')}</Link>
                <Link className='nav-link' onClick={() => handleClick(`${t('languages.lang2')}`)}>{t('languages.lang2')}</Link>
              </div>
            </div>
          </li>
        </ul>
        <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <span className='bar'></span>
          <span className='bar'></span>
          <span className='bar'></span>
        </div>
      </nav>
      <div className='header'>
        {/* <h6 className='text-white header-subtitle'>by ruslan mammadov</h6> */}
        {/* <h1 className='text-white header-title'>Mario russo</h1> */}
        <div className='bottom'>
          <div className='socials'>
            <a target='_blank' href='https://instagram.com/mariorusso'><i className='fa-brands fa-instagram'></i></a>
          </div>
          <div onClick={scrollToDown} className='mouse'></div>
          <div ></div>
        </div>
      </div>
    </header>
  )
}

export default Navbar